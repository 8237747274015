<template>
  <div id="home">
    <NavBar :currentRoute="'Settings'"/>
    <Settings/>
    <About/>
  </div>
</template>

<script>
import Settings from "@/components/Settings";
import NavBar from "@/components/NavBar";
import About from "@/components/About";

export default {
  name: "SettingsView",
  components: {About, NavBar, Settings}
}
</script>